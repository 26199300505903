// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flag-select {
  position: relative;
  display: inline-block;
  font-size: 16px; /* Adjust font size as needed */
  margin-left: 10px;
}

.flag-select-button {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff; /* Ensure background is not transparent */
  color: #333; /* Text color */
}

.flag-select-options {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
}

.flag-option {
  padding: 5px 10px;
  cursor: pointer;
  color: #333; /* Text color */
}

.flag-option:hover {
  background-color: #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/i18n/FlagSelect.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,eAAe,EAAE,+BAA+B;EAChD,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB,EAAE,yCAAyC;EACjE,WAAW,EAAE,eAAe;AAC9B;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,+CAA+C;EAC/C,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW,EAAE,eAAe;AAC9B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".flag-select {\n  position: relative;\n  display: inline-block;\n  font-size: 16px; /* Adjust font size as needed */\n  margin-left: 10px;\n}\n\n.flag-select-button {\n  cursor: pointer;\n  padding: 5px 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  background-color: #fff; /* Ensure background is not transparent */\n  color: #333; /* Text color */\n}\n\n.flag-select-options {\n  position: absolute;\n  background-color: #f9f9f9;\n  min-width: 50px;\n  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n  z-index: 1;\n  border-radius: 5px;\n}\n\n.flag-option {\n  padding: 5px 10px;\n  cursor: pointer;\n  color: #333; /* Text color */\n}\n\n.flag-option:hover {\n  background-color: #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
